    @import "styles/variables";
    @import "styles/mixins";

.list {
    display: grid;
    grid-template-columns: 1fr;
    gap: $spacing-5;
    width: 100%;
}

.fallbackText {
    @include heading-2;

    padding-top: $spacing-8;
    text-align: center;
    font-weight: 400;
    color: $color-base-500;

    @include breakpoint-md-above {
        grid-column: span 3;
    }
}

.loadMore {
    margin-top: $spacing-4;
    margin-left: auto;
    margin-right: auto;
}

.wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
}
