    @import "styles/variables";
    @import "styles/mixins";

.container {
    position: relative;
}

.toggleActive {
    background-color: #44444b;
}

.backdrop {
    position: fixed;
    z-index: -1;
    inset: 0;
    background: rgb(0 0 0 / 50%);
}

.panel {
    position: absolute;
    top: 100%;
    right: 0;
    overflow: hidden;
    width: 100vw;
    background: $color-base-900;
    color: $color-base-white;
    border-top: 1px solid #252525;
    box-shadow: -0.125rem 0.125rem 0.5rem 0 rgb(0 0 0 / 50%);
    z-index: 10;

    @include breakpoint-lg-above {
        width: 20rem;
        background-color: $color-base-white;
        color: $color-base-700;
        border-top: 0;
        transition: width 0.2s ease-in-out;
    }

    &.withProducts {
        @include breakpoint-lg-above {
            width: calc(100vw - #{$grid-spacing-desktop * 2});
            display: grid;
            grid-template-columns: 20rem 1fr;
        }

        @include breakpoint-xl-above {
            width: calc(#{$grid-container-max-width} - #{$grid-spacing-desktop * 2});
        }
    }
}

.list {
    @include reset-spacings;

    list-style: none;
}

.secondLevelList {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1;
    background: $color-base-900;

    @include breakpoint-lg-above {
        background-color: $color-base-white;
    }
}

.listItem {
    @include reset-spacings;

    &:not(:last-child) {
        border-bottom: 1px solid #252525;
    }

    @include breakpoint-lg-above {
        width: 20rem;

        &:not(:last-child) {
            border-bottom: 1px solid #d9d9d9;
        }
    }
}

.listItemButton {
    @include button-reset;

    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: $spacing-1;
    width: 100%;
    padding: $spacing-2 $spacing-3;
    font-size: 0.9375rem;
    line-height: 1.46;
    font-weight: $font-weight-regular;

    svg {
        width: 13px;
        height: 13px;
    }

    @include breakpoint-lg-above {
        svg {
            width: 15px;
            height: 15px;
        }

        &:hover {
            background-color: #e8edf3;
        }

        &.active {
            background-color: #6b6b7b;
            color: $color-base-white;
        }
    }
}

.backButton {
    background-color: #1c1c1c;
    justify-content: flex-start;

    @include breakpoint-lg-above {
        background-color: #f0f0f0 !important;
    }
}

.chevronInverse {
    transform: rotate(180deg);
}
