    @import "styles/variables";
    @import "styles/mixins";

.layout {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

.content {
    @include container;

    display: flex;
    flex-direction: column;
    flex: 1;
    padding-top: $spacing-8;
    padding-bottom: $spacing-9;

    @include breakpoint-sm-below {
        padding-top: $spacing-5;
        padding-bottom: $spacing-8;
    }

    &.noTopBottomPadding {
        padding-top: 0 !important;
        padding-bottom: 0 !important;
    }

    &.noTopPadding {
        padding-top: 0 !important;
    }
}
