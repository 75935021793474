    @import "styles/variables";
    @import "styles/mixins";

$content-size: 555px;

.container {
    @include full-viewport-width;

    isolation: isolate;
    position: relative;
    height: 562px;

    @include breakpoint-sm-below {
        height: 581px;
    }

    @include breakpoint-sm-only {
        height: 530px;
    }

    &::after {
        content: "";
        position: absolute;
        inset: 0;
        z-index: 1;
        width: 100%;
        background: linear-gradient(75deg, rgb(0 0 0), rgb(0 0 0 / 10%) 75%);
    }
}

.image {
    position: absolute;
    inset: 0;
    z-index: 1;
}

.contentWrapper {
    @include container;

    position: absolute;
    inset: 0;
    z-index: 2;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}

.content {
    color: $color-base-white;
    padding-bottom: $spacing-8;
    max-width: calc($content-size + $spacing-8);

    @include breakpoint-sm-only {
        max-width: calc($content-size + (2 * $spacing-5));
    }

    a {
        color: $color-base-white;
        text-decoration: none;
    }
}

.categoriesAndDate {
    z-index: 3;
}

.title {
    @include reset-spacings;
    @include heading-1;
    @include ensure-max-text-height(3);

    color: $color-base-white;
    margin-top: $spacing-1;

    .linkMask {
        position: absolute;
        inset: 0;
    }
}

.subtitle {
    @include reset-spacings;
    @include paragraph;
    @include ensure-max-text-height(3);

    margin-top: $spacing-3;
}

.readMore {
    @include reset-spacings;
    @include text-small;

    align-self: flex-start;
    align-items: center;
    display: inline-flex;
    gap: $spacing-1;
    margin-top: $spacing-4;
}
