    @import "styles/variables";
    @import "styles/mixins";

.storiesContainer {
    display: grid;
    gap: $spacing-5;
    grid-template-columns: 1fr;

    @include breakpoint-sm-above {
        grid-template-columns: repeat(2, 1fr);
    }

    @include breakpoint-md-above {
        grid-template-columns: repeat(3, 1fr);
    }
}

.noStories {
    padding: $spacing-10 0;
    margin: 0 auto;
    text-align: center;

    @include breakpoint-md-above {
        padding: 11rem 0;
    }
}

.noStoriesTitle {
    margin-top: $spacing-4;
    margin-bottom: $spacing-2;
}

.noStoriesSubtitle {
    color: $color-base-500;
    margin: 0;
}
