    @import "styles/variables";
    @import "styles/mixins";

@import "variables";
@import "mixins";

// TODO: Figure out how to force a specific order of injected styles to prevent specificity issues
.button.button {
    position: fixed;
    bottom: $spacing-9;
    right: $spacing-9;
    display: none;
    font-size: $font-size-xs;
    background: $color-base-white;
    height: $spacing-6;
    width: $spacing-6;
    align-items: center;
    justify-content: center;
    z-index: 20;
    padding: 0;

    > * {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    @include breakpoint-sm-only {
        right: $spacing-4;
        bottom: $spacing-4;
    }

    @include breakpoint-sm-below {
        right: $spacing-3;
        bottom: $spacing-3;
    }

    &.visible {
        display: flex;
    }
}

.icon {
    width: $spacing-3;
    height: $spacing-3;
    transform: rotate(180deg);
}
