$font-weight-regular: var(--prezly-font-weight-regular, 400);
$font-weight-medium: var(--prezly-font-weight-medium, 500);
$font-weight-bold: var(--prezly-font-weight-bold, 600);
$font-weight-bolder: var(--prezly-font-weight-bolder, 900);
$font-size-xxs: 0.75rem; // 12px
$font-size-xs: 1rem; // 16px
$font-size-s: 1.125rem; // 18px
$font-size-m: 1.375rem; // 22px
$font-size-l: 1.625rem; // 26px
$font-size-xl: 2.625rem; // 42px
$font-size-labels: 0.875rem; // 14px

$line-height-xxs: 0.9rem; // 14.4px
$line-height-xs: 1.5rem; // 24px
$line-height-s: 1.575rem; // 25.2px
$line-height-m: 1.925rem; // 30.8px
$line-height-l: 2.275rem; // 36.4px
$line-height-xl: 3.625rem; // 58px
$line-height-labels: 1.05rem; // 16.8px
