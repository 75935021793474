    @import "styles/variables";
    @import "styles/mixins";

$avatar-size: 60px;

// This class is attached to ContentRenderer so that we can
// modify some global styles for components that are coming
// from @prezly/content-renderer-react-js
.renderer {
    :global {
        /* stylelint-disable selector-class-pattern, max-nesting-depth, selector-max-compound-selectors */
        .prezly-slate-document {
            color: inherit;

            > *:last-child {
                margin-bottom: 0 !important;
            }
        }

        .prezly-slate-image,
        .prezly-slate-gallery,
        .prezly-slate-embed {
            margin-top: $spacing-6;
            margin-bottom: $spacing-6;
        }

        .prezly-slate-image-rollover {
            width: 100%;
            z-index: 0;
        }

        .prezly-slate-image-rollover__caption-icon-container {
            background: $color-base-900;
            border-radius: 0;
        }

        .prezly-slate-image-rollover__caption-icon {
            width: $spacing-3;
            height: $spacing-3;
        }

        .prezly-slate-image-rollover__caption {
            &:not(.prezly-slate-image-rollover__caption--empty) {
                .prezly-slate-image-rollover__caption-icon-container {
                    margin-top: 0;
                }
            }
        }

        .prezly-slate-image-rollover__dim {
            background-color: rgba($color-base-900, 0.6);
        }

        .prezly-slate-image-rollover__caption-text {
            @include text-tiny;

            height: $line-height-xxs * 2;
            padding: $spacing-3;
            color: $color-base-white;
        }

        .prezly-slate-iframely-embed {
            iframe {
                border-color: $color-borders !important;
            }
        }

        .prezly-slate-image__caption {
            font-size: $font-size-labels;
        }

        .prezly-slate-bookmark,
        .prezly-slate-story-bookmark {
            margin: $spacing-6 0;
            border-radius: 0;
            border-color: $color-borders;
        }

        .prezly-slate-contact--card {
            border-radius: 0;
            box-shadow: none;
        }

        .prezly-slate-contact__wrapper {
            gap: $spacing-2;
            border-radius: 0;
        }

        .prezly-slate-contact__name {
            font-size: $font-size-m;
        }

        .prezly-slate-contact__job-description {
            color: $color-base-400;
            font-size: inherit;
        }

        .prezly-slate-contact__avatar {
            width: $avatar-size;
            height: $avatar-size;
            border-radius: 0;
        }

        .prezly-slate-social-fields--card {
            gap: 20px;
        }

        .prezly-slate-social-field__link {
            @include text-label;

            color: var(--prezly-accent-color);
            font-weight: $font-weight-bold;
        }

        .prezly-slate-callout {
            margin: $spacing-6 auto;
            line-height: $line-height-m;
            font-weight: normal;
            background-color: color-mix(in srgb, var(--prezly-accent-color) 8%, transparent);
            border-color: color-mix(in srgb, var(--prezly-accent-color) 60%, transparent);

            &::before {
                line-height: $line-height-s;
            }
        }

        mark {
            @include highlighted-text;
        }

        .prezly-slate-button-block {
            background-color: var(--prezly-accent-color);
            color: $color-base-white;

            &:hover,
            &:active {
                background-color: var(--prezly-accent-color-darkest);
            }

            &--outlined {
                background-color: $color-base-white;
                color: $color-base-600;

                &:hover,
                &:active {
                    background-color: $color-base-50;
                    border-color: $color-base-200;
                }
            }
        }
        /* stylelint-enable selector-class-pattern, max-nesting-depth, selector-max-compound-selectors */
    }
}

// This class is attached to <body> when ContentRenderer is mounted
// because Lightbox component is mounted outside of this component
.body {
    :global {
        /* stylelint-disable selector-class-pattern, max-nesting-depth */
        .ReactModal__Overlay {
            z-index: 999;
        }

        .prezly-slate-lightbox__close {
            @include link-primary;
        }

        .prezly-slate-lightbox__nav-button {
            color: $color-base-600;
            background-color: rgba($color-base-white, 0.1);
            transition: background-color 0.2s ease-in-out;

            &:hover {
                color: $color-base-900;
                background-color: rgba($color-base-white, 0.3);
            }
        }

        .prezly-slate-lightbox__download {
            background-color: var(--prezly-accent-color);
            border-color: var(--prezly-accent-color);
            color: $color-base-white;
            border-radius: 0;

            &:hover,
            &:focus {
                background-color: var(--prezly-accent-color-darkest);
                border-color: var(--prezly-accent-color-darkest);
            }

            &:active {
                background-color: var(--prezly-accent-color-darkest);
                border-color: var(--prezly-accent-color-darkest);
            }
        }

        .prezly-slate-lightbox__pinterest {
            display: none;
        }

        /* stylelint-enable selector-class-pattern, max-nesting-depth */
    }
}
