    @import "styles/variables";
    @import "styles/mixins";

.container {
    overflow: hidden;
    border: 1px solid $color-borders;
    padding: $spacing-4;

    /* stylelint-disable max-nesting-depth */
    // This class applies mobile styling but can also be forced through props.
    // The component will apply this class automatically when on mobile screen.
    // &.compact {
    //     // margin-bottom: -70px;
    //     .avatar {
    //         margin-bottom: $spacing-3;
    //     }

    //     .links {
    //         display: block;
    //         margin-bottom: 0;
    //     }
    // }
    /* stylelint-enable max-nesting-depth */
}

.contentTitle {
    display: flex;
    flex-direction: column;
    gap: $spacing-3;

    @include breakpoint-md-above {
        flex-direction: row;
    }
}

.avatar {
    width: 3.75rem;
    height: 3.75rem;
}

.name {
    @include heading-3;

    color: $color-base-700;
    margin: 0;
}

.position {
    @include text-small;

    margin: $spacing-half auto 0;
    color: $color-base-500;
}

.links {
    margin-top: $spacing-3;
}

.primaryContacts {
    display: flex;
    flex-direction: column;
    gap: $spacing-3;

    @include breakpoint-md-above {
        flex-flow: row wrap;
    }
}

.link {
    @include link-primary;

    display: flex;
    align-items: center;
    overflow: hidden;
}

.linkText {
    @include text-small;

    overflow: hidden;
    overflow-wrap: break-word;
}

.socials {
    margin-top: $spacing-3;
    display: flex;
    gap: $spacing-3;
}

.icon {
    width: 1.125rem;
    height: 0.9rem;
    flex-shrink: 0;
    margin-right: $spacing-1;
}
