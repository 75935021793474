    @import "styles/variables";
    @import "styles/mixins";

.link {
    display: block;
    padding: $spacing-2;
    text-decoration: none;
    color: $color-base-700;
    font-weight: $font-weight-medium;

    &.active {
        background: $color-base-50;
    }
}

.item {
    width: max-content;
    min-width: 100%;
    max-width: 100%;

    &:first-child > .link {
        padding-top: $spacing-3;
    }

    &:last-child > .link {
        padding-bottom: $spacing-3;
    }
}
