    @import "styles/variables";
    @import "styles/mixins";

$story-container-max-width: 720px;

.container {
    background: $color-base-white;
    position: relative;

    @include breakpoint-md-above {
        margin: 0 auto;
        max-width: $story-container-max-width;
    }

    &.withTopSpacing {
        margin-top: $spacing-8;
    }
}

.mainImage {
    @include full-viewport-width;

    margin-top: $spacing-5;
    margin-bottom: $spacing-5;

    @include breakpoint-md-above {
        margin-top: $spacing-8;
    }

    @include breakpoint-lg-above {
        margin-bottom: $spacing-8;
    }
}

.header {
    @include breakpoint-lg-above {
        display: flex;
        align-items: center;
        gap: $spacing-8;
        justify-content: space-between;
    }
}

.meta {
    margin: $spacing-5 0;
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    gap: $spacing-3;

    @include breakpoint-lg-above {
        margin: 0;
    }
}

.metaItem {
    @include paragraph;

    display: grid;
    grid-template-columns: 1.25rem 1fr;
    gap: $spacing-2;
    color: $color-base-500;
}

.metaIcon {
    width: 1.25rem;
    height: 1.25rem;
    align-self: center;
}

.metaContent {
    @include reset-spacings;

    font: inherit;
    color: inherit;
}

.storyLinksWrapper {
    margin: $spacing-5 0;

    @include breakpoint-lg-above {
        margin: 0;
        position: absolute;
        left: -4.5rem;
        top: 0;
        bottom: 0;
    }

    @include breakpoint-xl-above {
        left: -9rem;
    }
}

.storyLinks {
    margin: 0;

    @include breakpoint-lg-above {
        position: sticky;
        top: $spacing-2;
        flex-direction: column;
        padding: $spacing-1;
        background: $color-base-white;
    }
}
