    @import "styles/variables";
    @import "styles/mixins";

.container {
    @include full-viewport-width;

    background-color: $color-base-800;
    display: flex;
    align-items: flex-end;
    min-height: 290px;
    padding-bottom: $spacing-8;
    padding-top: $spacing-8;
    margin-bottom: $spacing-8;

    &::after {
        position: absolute;
        content: "";
        inset: 0;
        z-index: 2;
        background: linear-gradient(74deg, rgb(0 0 0 / 80%) 0%, rgb(0 0 0 / 0%) 62.37%);
    }
}

.text {
    position: relative;
    z-index: 3;
}

.image {
    position: absolute;
    inset: 0;
    z-index: 1;
}
