    @import "styles/variables";
    @import "styles/mixins";

.container {
    display: flex;
    background: $color-base-900;
    color: $color-base-white;
}

.containerInner {
    position: relative;
    z-index: 1;

    @include breakpoint-lg-below {
        padding-right: 0;
    }
}

.nav {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.newsroom {
    color: inherit;
    text-decoration: none;
}

.title {
    font-size: 1.75rem;
    line-height: 2.5rem;
    text-transform: uppercase;
    color: inherit;

    @include breakpoint-lg-below {
        font-size: 1.25rem;
    }

    &.hidden {
        @include sr-only;
    }
}

.logo {
    width: auto;
    max-width: 6.375rem;
    max-height: 3.5rem;
}

.navigation {
    @include reset-spacings;

    list-style: none;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
}

.navigationItem {
    @include reset-spacings;
}

.navigationButton {
    font: inherit;
    color: inherit;
    opacity: 1 !important;
    border: 0;
    padding: 0 ($spacing-1 + $spacing-half);
    min-width: 2.75rem;
    height: 3.125rem;
    outline: none !important;
    box-shadow: none !important;

    &:hover {
        background-color: #44444b;
    }

    @include breakpoint-md-above {
        svg {
            width: 1.125rem;
            height: 1.125rem;
        }
    }

    @include breakpoint-lg-above {
        min-width: 4rem;
        height: 4rem;

        svg {
            width: 1.5rem;
            height: 1.5rem;
        }
    }
}
