    @import "styles/variables";
    @import "styles/mixins";

.container {
    position: absolute;
    inset: 0;
    left: 20rem;
    height: 100%;
    overflow-y: auto;
    border-left: 1px solid #d9d9d9;
    padding: $spacing-5 $spacing-7;
}

.list {
    @include reset-spacings;

    list-style: none;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    column-gap: $spacing-5;
}

.item {
    @include reset-spacings;
}

.link {
    color: inherit;
    text-decoration: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: $spacing-1 0;
    height: 100%;

    &:hover {
        background-color: #e8edf3;
    }
}

.image {
    display: block;
    width: 100%;
    height: 90px;
    object-position: center;
    object-fit: contain;
}

.title {
    @include text-tiny;
}
