    @import "styles/variables";
    @import "styles/mixins";

.grid {
    display: grid;
    gap: $spacing-9 $spacing-6;

    /* stylelint-disable max-nesting-depth */
    @include breakpoint-md-above {
        grid-template-columns: repeat(6, 1fr);

        &.twoColumns {
            .card {
                grid-column: span 3;
            }
        }

        &.threeColumns {
            .card {
                grid-column: span 2;
            }
        }

        &.twoByThreeColumns {
            .card {
                grid-column: span 2;

                &:first-child,
                &:nth-child(2) {
                    grid-column: span 3;
                }
            }
        }
    }
    /* stylelint-enable max-nesting-depth */

    @include breakpoint-lg-below {
        grid-template-columns: 1fr;
    }
}
