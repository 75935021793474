    @import "styles/variables";
    @import "styles/mixins";

.disabled {
    pointer-events: none;
    opacity: 0.5;
}

.button {
    color: inherit;
    font: inherit;
}

.item {
    @include text-small;
}
