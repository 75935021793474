    @import "styles/variables";
    @import "styles/mixins";

.title {
    @include heading-1;

    color: $color-base-800;
    margin: 0 0 $spacing-3;
    font-weight: $font-weight-medium;

    mark {
        @include highlighted-text;
    }
}

.alignLeft {
    text-align: left;
}

.alignCenter {
    text-align: center;
}

.alignRight {
    text-align: right;
}
