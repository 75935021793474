    @import "styles/variables";
    @import "styles/mixins";

.topics {
    display: grid;
    grid-template-columns: repeat(1, 1fr);

    @include breakpoint-md-above {
        &.five {
            grid-template-columns: repeat(5, 1fr);
        }

        &.six {
            grid-template-columns: repeat(6, 1fr);
        }
    }

    margin-top: $spacing-5;
}

.wrapper {
    &.usedInSearchOverlay .topics {
        gap: $spacing-2;
    }

    &.usedInHome .topics {
        gap: $spacing-3;
    }
}
