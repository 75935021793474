    @import "styles/variables";
    @import "styles/mixins";

.container {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 3.5%;
    padding-top: 3.75rem;
}

.title {
    color: #878792;
    font-size: 0.9375rem;
    line-height: 1.6667;
    margin-bottom: $spacing-3;
}

.disclosure {
    border-bottom: 1px solid #3c3c42;

    &.open {
        border-color: $color-base-white;
    }
}

.panel {
    overflow: hidden;
}

.disclosureButton {
    @include button-reset;

    width: 100%;
    padding: $spacing-2 $spacing-3-5;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 0.9375rem;
    line-height: 1.6667;

    &::after {
        content: "";
        border-width: 0 3.5px 3.5px;
        border-style: solid;
        /* stylelint-disable-next-line value-keyword-case */
        border-color: transparent transparent currentColor;
        width: 0;
        height: 0;
        transform: scale(-1);
    }

    &.open {
        position: relative;

        &::before {
            content: "";
            display: block;
            position: absolute;
            left: 0;
            right: 0;
            top: -1px;
            height: 1px;
            background-color: $color-base-white;
        }

        &::after {
            transform: scale(1);
        }
    }
}

.content {
    padding: 0 $spacing-3-5 $spacing-3-5;

    @include breakpoint-lg-above {
        padding: 0;
    }
}

.link {
    width: 100%;
    display: block;
    color: #e4e7eb;
    font-size: 0.8125rem;
    line-height: 1.923;
    text-decoration: none;

    &:hover {
        color: #878792;
    }
}

.form {
    display: flex;
    justify-content: space-between;
    gap: 1rem;
    overflow: hidden;
    position: relative;
    border: 2px solid #3c3c42;
    padding: 0.375rem;
}

.input {
    width: 100%;
    flex-grow: 1;
    border: 0;
    padding: 0 0.25rem 0 1.75rem;
    height: 1.75rem;
    font-size: 0.75rem;
    background: transparent;
    color: #fff;
}

.globeIcon {
    position: absolute;
    left: 0.5625rem;
    top: 50%;
    transform: translateY(-50%);
    width: 1.125rem;
    height: 1.125rem;
    color: #d24615;
}

.submit {
    display: inline-block;
    border: 1px solid rgb(255 255 255 / 40%);
    border-radius: 1px;
    background-color: #2d75c7;
    transition: background-color 0.25s;
    font-size: 0.75rem;
    min-width: 5.75rem;
    text-align: center;
    color: #fff;
    padding: 0.25rem 1.5rem;
    line-height: 1.6667;
    flex: 0 0 auto;
    height: 1.75rem;

    &:hover {
        background-color: #34568e;
    }

    &:focus,
    &:active {
        background-color: #6694ff;
    }

    &:focus {
        outline: thin dotted #333;
        outline-offset: -0.125rem;
    }
}
