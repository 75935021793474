    @import "styles/variables";
    @import "styles/mixins";

.container {
    @include breakpoint-lg-below {
        width: 100%;
    }

    @include breakpoint-md-above {
        &:not(:last-child) {
            margin-right: $spacing-3;
        }
    }

    @include breakpoint-md-below {
        &:not(:last-child) {
            margin-bottom: $spacing-1;
        }

        padding: $spacing-2 $spacing-3;
        border: 1px solid $color-base-200;

        .button {
            padding: 0;
        }
    }

    .button {
        background: $color-base-white;
        color: $color-base-600;
        width: 100%;

        &:hover {
            opacity: 1;
            color: $color-base-700;
        }

        &:active {
            color: $color-base-700;
        }
    }

    .buttonContent {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
    }
}

.menu {
    z-index: 1;
    width: max-content;
    max-height: 250px;
    border: 1px solid $color-base-200;
    overflow-y: auto;

    @include breakpoint-md-above {
        max-width: 200px;
    }

    @include breakpoint-md-below {
        max-width: none !important;
        margin-top: $spacing-2;

        // The child component considers 100% as the available width within its parent,
        // excluding borders as they are considered outside the content area.
        // This causes an issue when calculating the menu width. For instance, if the container width is `399px`,
        // the menu ends up being `397px`, resulting in a glitch-like appearance.
        // To resolve this, we employ a workaround hack to ensure the menu is displayed as full-width on mobile devices.
        left: -1px !important;
        right: 0;
        width: calc(100% + 2px);
    }
}

.title {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.caret {
    margin-left: $spacing-2;
    flex-shrink: 0;
    width: $spacing-3;
    height: $spacing-3;
}

.caretOpen {
    transform: rotate(180deg);
}

.panel {
    @include shadow-l;

    position: absolute;
    z-index: 10;
    margin-top: $spacing-3;
    background-color: $color-base-white;
    padding: $spacing-3;
}

.list {
    list-style-type: none;
    margin: 0;
    padding: 0;
}

.listItemInner {
    @include text-label;

    display: flex;
    align-items: center;
    padding: $spacing-2;

    input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
    }

    .text {
        position: relative;
        display: flex;
        align-items: center;
        gap: $spacing-half;
        padding-right: $spacing-3;
        user-select: none;
        overflow: hidden;
    }

    .label {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }

    .check {
        position: absolute;
        right: 3px;
        top: calc(50% - 2px); // -2px is to visually center it with text
        display: none;
        width: 5px;
        height: 10px;
        border: solid $color-accent;
        border-width: 0 1px 1px 0;
        transform: translateY(-50%) rotate(45deg);
    }

    input:checked ~ .text {
        color: $color-accent !important;

        .check {
            display: block;
        }
    }

    &:hover {
        cursor: pointer;
        background-color: $color-base-100;
    }
}

.count {
    white-space: nowrap;
    opacity: 0.75;
}

.viewMore {
    @include text-label;

    border: 0;
    padding: $spacing-1 0;
    margin-top: $spacing-1;
    margin-bottom: -1 * $spacing-1;
    color: var(--prezly-accent-color);
    font-weight: $font-weight-bold;
    text-decoration: none;
    cursor: pointer;

    &:hover,
    &:focus {
        text-decoration: underline;
    }
}
