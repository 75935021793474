    @import "styles/variables";
    @import "styles/mixins";

@mixin quote-fix($direction) {
    &::before {
        #{$direction}: 0;
    }
}

.container {
    display: flex;
    margin: $spacing-6 auto;
}

.content {
    @include paragraph;

    padding: $spacing-3 $spacing-4;
    position: relative;

    &::before {
        content: "";
        position: absolute;
        top: 0;
        background-color: $color-base-800;
        width: 2px;
        height: 100%;
    }
}

.alignLeft {
    text-align: left;
    justify-content: flex-start;

    .content {
        @include quote-fix("left");
    }
}

.alignCenter {
    text-align: center;
    justify-content: center;

    .content {
        @include quote-fix("left");
    }
}

.alignRight {
    text-align: right;
    justify-content: flex-end;

    .content {
        @include quote-fix("right");
    }
}
