    @import "styles/variables";
    @import "styles/mixins";

.container {
    display: flex;
    gap: $spacing-1;
    align-items: center;
    margin: 0;
    flex-grow: 1;

    @include breakpoint-md-below {
        border: 1px solid $color-base-200;
        padding: $spacing-2 $spacing-3;
    }
}

.input {
    margin: 0;
    flex-grow: 1;

    input {
        padding: $spacing-2 $spacing-3;
        padding-left: 0;

        @include breakpoint-md-below {
            padding-right: 0;
            padding-top: $spacing-half;
            padding-bottom: $spacing-half;
        }
    }

    input,
    input:focus,
    input:active,
    input:hover {
        border: none;
    }

    /* clears the 'X' from Internet Explorer */
    input::-ms-clear,
    input::-ms-reveal {
        display: none;
        width: 0;
        height: 0;
    }

    /* clears the 'X' from Chrome */
    input::-webkit-search-decoration,
    input::-webkit-search-cancel-button,
    input::-webkit-search-results-button,
    input::-webkit-search-results-decoration {
        display: none;
    }
}

.icon {
    color: $color-base-500;
}
