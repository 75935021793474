    @import "styles/variables";
    @import "styles/mixins";

.container {
    position: relative;

    @include breakpoint-md-above {
        &:not(:last-of-type) {
            margin-bottom: 0;
        }

        &:hover .image {
            transform: scale(1.05);
        }

        &:hover .titleLink {
            color: $color-base-800;
            background-size: 100% 100%;
        }
    }
}

.imageWrapper {
    @include base-card-aspect-ratio;

    display: block;
    overflow: hidden;
    text-decoration: none;

    @include breakpoint-md-above {
        .image {
            transform: scale(1);
            transition: transform 0.25s ease-in-out;
        }
    }
}

.content {
    padding-top: $spacing-4 - $spacing-half;
}

.title {
    @include heading-3;
    @include ensure-max-text-height(4, $line-height-l);

    font-weight: $font-weight-medium;
    margin-top: $spacing-1;
    margin-bottom: 0;

    &.noDateAndCategories {
        @include breakpoint-md-above {
            @include ensure-max-text-height(5, $line-height-s);
        }
    }
}

.titleLink {
    text-decoration: none;
    color: inherit;
    width: calc(100%);
    background-image: linear-gradient(transparent calc(100% - 2px), $color-base-800 5px);
    background-repeat: no-repeat;
    background-size: 0% 100%;
    transition: background-size 0.7s;

    &:focus {
        color: $color-base-800;
    }
}
