@mixin base-card-aspect-ratio {
    /*
     Most near dimension to 362x248 that can give a round (un-odd) aspect ratio is 363x242 
     Most near aspect ratio to 363x242 is 3x2 
     Calculated on https://lawlesscreation.github.io/nearest-aspect-ratio/
    */
    aspect-ratio: 3 / 2;

    @supports not (aspect-ratio: auto) {
        height: 248px;
    }
}

@mixin topic-aspect-ratio-vertical {
    aspect-ratio: 2 / 3;

    @supports not (aspect-ratio: auto) {
        height: 306px;
    }
}

@mixin topic-aspect-ratio-horizontal {
    aspect-ratio: 7 / 3;

    @supports not (aspect-ratio: auto) {
        height: 317px;
    }
}

@mixin topic-aspect-ratio-horizontal-secondary {
    aspect-ratio: 5 / 1;

    @supports not (aspect-ratio: auto) {
        height: 108px;
    }
}

@mixin topic-aspect-ratio-mobile {
    aspect-ratio: 7 / 6;

    @supports not (aspect-ratio: auto) {
        height: 317px;
    }
}
