    @import "styles/variables";
    @import "styles/mixins";

.container {
    background: $color-base-white;
    border-bottom: 1px solid $color-base-200;
    padding: $spacing-2 0;
}

.nav {
    @include text-small;

    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    width: 100%;
    color: $color-base-800;
    font-weight: $font-weight-regular;

    @include breakpoint-sm-above {
        gap: $spacing-3;
    }

    @include breakpoint-md-above {
        gap: $spacing-4;
    }

    @include breakpoint-lg-above {
        gap: $spacing-5;
    }
}

.navRight {
    margin-right: -$spacing-2; // Compensate for button padding
    margin-left: auto;
    display: flex;
    align-items: center;
}

.siteLink {
    color: inherit;
    text-decoration: none;
    flex-shrink: 0;
}

.title {
    @include heading-3;
    @include reset-spacings;

    color: inherit;
    font-weight: inherit;
}

.searchToggle {
    color: inherit;
    font: inherit;
}
