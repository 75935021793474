    @import "styles/variables";
    @import "styles/mixins";

.container {
    margin: $spacing-6 auto;
    padding: $spacing-4;
    border: 1px solid $color-borders;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    gap: $spacing-4;

    @include breakpoint-md-above {
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }
}

.name {
    @include paragraph;

    margin: 0 auto $spacing-half;
    color: $color-base-700;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.type {
    @include text-tiny;

    margin: 0 auto;
    color: $color-base-400;
    font-weight: $font-weight-regular;
}

.link {
    @include text-tiny;

    color: $color-base-700;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: $spacing-2;
    text-decoration: none;
    border: 1px solid $color-borders;
    padding: $spacing-3 $spacing-5;
}

.icon {
    width: $spacing-2;
    height: $spacing-2;
}
