    @import "styles/variables";
    @import "styles/mixins";

.navigation {
    @include reset-spacings;

    list-style: none;
    display: flex;
    align-items: center;
    flex-wrap: nowrap;

    @include breakpoint-sm-above {
        gap: $spacing-half;
    }
}

.navigationItem {
    @include reset-spacings;
}

.button {
    color: inherit;
    font: inherit;
    position: relative;

    &.active {
        &::after {
            content: "";
            display: block;
            position: absolute;
            left: 0;
            right: 0;
            bottom: -14px;
            height: $spacing-half;
            background-color: $color-base-900;
        }
    }
}
