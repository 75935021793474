@mixin reset-spacings {
    padding: 0;
    margin: 0;
}

@mixin button-reset {
    padding: 0;
    outline: none;
    border: none;
    font: inherit;
    color: inherit;
    background-color: transparent;
    cursor: pointer;
}

/*
* Use this mixin inside a container to create a block that breaks outside of fixed width
* and stretches to the entire screen width
*/
@mixin full-viewport-width {
    position: relative;
    width: calc(100vw - var(--scrollbar-width));
    left: 50%;
    right: 50%;
    margin-left: calc(-50vw + var(--scrollbar-width) / 2);
    margin-right: -50vw;
}

@mixin all-link-states {
    &,
    &:active,
    &:focus,
    &:focus-within,
    &:hover {
        @content;
    }
}
