    @import "styles/variables";
    @import "styles/mixins";

@import "variables";
@import "mixins";

.container {
    height: 40px;
}

.banner {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 100;
}

.notification {
    min-height: 40px;
    margin: 0;
    padding: $spacing-1;
    vertical-align: middle;
    background-color: $color-base-300;
    color: $color-text;
    font-size: inherit;
    font-weight: $font-weight-medium;
    line-height: 1.4;
    text-align: center;

    a {
        &,
        &:hover,
        &:active,
        &:visited,
        &:focus {
            color: inherit;
        }
    }

    &.success {
        background-color: $color-success-shade;
        color: $color-base-white;
    }

    &.info {
        background-color: $color-base-300;
    }

    &.danger {
        background-color: $color-error-shade;
        color: $color-base-white;
    }

    &.warning {
        background-color: $color-warning-bg;
    }
}
