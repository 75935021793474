    @import "styles/variables";
    @import "styles/mixins";

.error {
    display: flex;
    flex: 1;
    justify-content: center;
    padding: $spacing-7 0;

    @include breakpoint-sm-above {
        padding: $spacing-10 0;
    }
}
