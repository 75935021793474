    @import "styles/variables";
    @import "styles/mixins";

.list {
    display: flex;
    gap: $spacing-1;
    padding: $spacing-3;
    border: 1px solid $color-base-200;
    margin: $spacing-5 0;
    overflow-x: auto;

    @include breakpoint-sm-below {
        @include full-viewport-width;

        padding: $spacing-3 $spacing-4;
    }
}

.item {
    @include button-reset;

    display: flex;
    align-items: center;
    justify-content: center;
    padding: $spacing-2 $spacing-3;
    white-space: nowrap;

    &:hover {
        background-color: $color-base-100;
    }

    &.selected {
        background-color: $color-accent;
        color: $color-base-white;
    }

    &.disabled {
        opacity: 0.5 !important;
        pointer-events: none !important;
    }
}
