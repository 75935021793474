    @import "styles/variables";
    @import "styles/mixins";

.container {
    background-color: $color-base-900;
    border-top: 1px solid #3c3c42;
    color: #878792;
}

:where(.container ul) {
    @include reset-spacings;

    list-style: none;
}

:where(.container li) {
    @include reset-spacings;
}
