    @import "styles/variables";
    @import "styles/mixins";

.button {
    @include button-reset;

    transition: background-color 0.1s;
    color: $color-base-400;
    
    &:disabled {
        opacity: .5;
        pointer-events: none;
    }

    &:hover:not(:disabled) {
        color: $color-accent;
        background-color: rgba($color-base-50, 0.5);
    }

    &:active:not(:disabled) {
        background-color: $color-base-50;
    }
    
    &.selected {
        color: $color-accent;
        font-weight: $font-weight-bold;
    }

    > svg {
        position: relative;
        top: -1px;
    }
}

.item {
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
}