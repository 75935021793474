    @import "styles/variables";
    @import "styles/mixins";

.wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
}

.button {
    margin: $spacing-5 auto 0;

    @include breakpoint-sm-below {
        width: 100%;
    }
}
