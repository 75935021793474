    @import "styles/variables";
    @import "styles/mixins";

.wrapper {
    display: inline-flex;
    flex-wrap: wrap;
    align-items: center;
    position: relative;
}

.highlighted {
    color: $color-base-white;

    .categories a {
        @include all-link-states {
            color: $color-base-white !important;
        }
    }
}

.default {
    color: $color-base-500;

    .categories a {
        @include all-link-states {
            color: $color-accent !important;
        }
    }
}

.categories {
    display: contents;

    a:hover span {
        text-decoration: underline;
    }
}
