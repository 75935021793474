@mixin container {
    width: 100%;
    padding-right: $grid-spacing-mobile;
    padding-left: $grid-spacing-mobile;

    @include breakpoint-md-above {
        padding-left: $grid-spacing-tablet;
        padding-right: $grid-spacing-tablet;
    }

    @include breakpoint-lg-above {
        padding-left: $grid-spacing-desktop;
        padding-right: $grid-spacing-desktop;
    }

    @include breakpoint-xl-above {
        max-width: $grid-container-max-width;
        margin-left: auto;
        margin-right: auto;
    }
}
