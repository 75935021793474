    @import "styles/variables";
    @import "styles/mixins";

.container {
    border: 0;
    padding: 0;
}

.contentTitle {
    @include breakpoint-md-above {
        flex-direction: column;
    }
}

.avatar {
    width: 100%;
    height: auto;
    aspect-ratio: 3 / 2;
    object-fit: cover;

    @supports not (aspect-ratio: 1 / 1) {
        height: 240px;
    }
}

.avatarEmpty {
    border: 1px solid $color-base-200;
}

.disclaimer {
    @include paragraph;

    border: 1px solid $color-base-200;
    padding: $spacing-5;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: $color-base-600;
}
