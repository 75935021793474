    @import "styles/variables";
    @import "styles/mixins";

.container {
    border-top: solid 2px #3c3c42;
    padding: 2.625rem 0 3.125rem;

    @include breakpoint-lg-above {
        border-width: 1px;
        margin-top: 1.875rem;
    }
}

.containerInner {
    @include breakpoint-lg-above {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
    }
}

.list {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: $spacing-1;
    margin-bottom: $spacing-1;

    @include breakpoint-lg-above {
        flex-direction: row;
        text-align: left;
        gap: $spacing-2;
    }
}

.link {
    color: #e4e7eb;
    white-space: nowrap;
    text-decoration: none;
    font-size: 0.625rem;
    font-weight: $font-weight-bold;

    &:hover {
        color: #878792;
    }

    @include breakpoint-lg-above {
        font-size: 0.6875rem;
    }
}

.copyright {
    margin: 0;
    color: #878792;
    font-size: 0.625rem;
    line-height: 1.8;
    text-align: center;

    @include breakpoint-lg-above {
        text-align: left;
        font-size: 0.6875rem;
    }
}

.brandImage,
.brandImageHover {
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center;
}

.brandImageHover {
    position: absolute;
    inset: 0;
    opacity: 0;
    transition: opacity 0.25s ease;
}

.brandLink {
    display: block;
    margin: 1.25rem auto 0;
    text-decoration: none;
    position: relative;
    width: 9.375rem;
    height: 1.25rem;

    @include breakpoint-lg-above {
        margin: 0;
    }

    &:hover {
        .brandImageHover {
            opacity: 1;
        }
    }
}
