@import "../variables/breakpoints";

@mixin breakpoint-sm-below {
    @media screen and (max-width: ($breakpoint-sm - 1px)) {
        @content;
    }
}

@mixin breakpoint-sm-above {
    @media screen and (min-width: $breakpoint-sm) {
        @content;
    }
}

@mixin breakpoint-sm-only {
    @media screen and (min-width: $breakpoint-sm) and (max-width: ($breakpoint-md - 1px)) {
        @content;
    }
}

@mixin breakpoint-md-below {
    @media screen and (max-width: ($breakpoint-md - 1px)) {
        @content;
    }
}

@mixin breakpoint-md-above {
    @media screen and (min-width: $breakpoint-md) {
        @content;
    }
}

@mixin breakpoint-md-only {
    @media screen and (min-width: $breakpoint-md) and (max-width: ($breakpoint-lg - 1px)) {
        @content;
    }
}

@mixin breakpoint-lg-below {
    @media screen and (max-width: ($breakpoint-lg - 1px)) {
        @content;
    }
}

@mixin breakpoint-lg-above {
    @media screen and (min-width: $breakpoint-lg) {
        @content;
    }
}

@mixin breakpoint-lg-only {
    @media screen and (min-width: $breakpoint-lg) and (max-width: ($breakpoint-xl - 1px)) {
        @content;
    }
}

@mixin breakpoint-xl-above {
    @media screen and (min-width: $breakpoint-xl) {
        @content;
    }
}

@mixin breakpoint-xl-below {
    @media screen and (max-width: ($breakpoint-xl - 1px)) {
        @content;
    }
}
