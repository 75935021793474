    @import "styles/variables";
    @import "styles/mixins";

.wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: $spacing-1;

    .title {
        @include reset-spacings;
    }

    .description {
        @include reset-spacings;

        font-weight: $font-weight-regular !important;
    }

    // Sizes
    &.medium {
        .title {
            @include heading-1;
        }

        .description {
            @include heading-3;
        }
    }

    &.small {
        .title {
            @include heading-2;
        }

        .description {
            @include paragraph;
        }
    }

    // Colors
    &.dark {
        .title {
            color: $color-base-900;
        }

        .description {
            color: $color-base-500;
        }
    }

    &.light {
        .title,
        .description {
            color: $color-base-white;
        }
    }
}
