    @import "styles/variables";
    @import "styles/mixins";

.container {
    @include breakpoint-lg-above {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        margin-top: 4.375rem;
    }
}

.regionSelector {
    border-bottom: solid 2px #3c3c42;
    padding: 2.1875rem 1.25rem 2.25rem;

    // TODO: Sync with `desktop-large-up` breakpoint
    @include breakpoint-lg-below {
        @include full-viewport-width;
    }

    @include breakpoint-lg-above {
        border-bottom: 0;
        padding: 0;
    }
}

.regionSelectorLink {
    display: inline-flex;
    align-items: center;
    padding: 0.3125rem 0.5625rem 0.375rem 0.625rem;
    background-color: #3c3c42;
    color: #fff;
    text-decoration: none;
    white-space: nowrap;
    font-size: 0.8125rem;
    line-height: 1.932;
}

.nav {
    padding: 1.875rem 1.25rem;

    @include breakpoint-lg-above {
        padding: 0;
    }
}

.links {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: $spacing-3;

    @include breakpoint-lg-above {
        justify-content: flex-end;
    }
}

.link {
    width: 100%;
    display: block;
    color: #e4e7eb;
    font-size: 0.8125rem;
    line-height: 1.923;
    text-decoration: none;

    &:hover {
        color: #878792;
    }
}

.socialLinks {
    margin-top: 1.1875rem;
    display: flex;
    justify-content: center;
    gap: 0.25rem;

    @include breakpoint-lg-above {
        justify-content: flex-end;
    }
}

.socialLink {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #3c3c42;
    color: #fff;
    padding: 0 0.25rem;
}

.socialIcon {
    width: 2rem;
    height: 2rem;
    object-fit: contain;
    object-position: center;
}
