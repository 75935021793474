    @import "styles/variables";
    @import "styles/mixins";

.wrapper {
    @include full-viewport-width;

    padding-top: $spacing-8;
    padding-bottom: $spacing-8;
    background-color: $color-base-900;
    color: $color-base-white;
}

.container {
    display: grid;
    grid-template-columns: 1fr;
    gap: $spacing-5;

    @include breakpoint-md-above {
        grid-template-columns: 1fr 1fr;
    }
}

.form {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.inlineForm {
    display: grid;
    gap: $spacing-2;
    width: 100%;
    margin: 0;

    label {
        margin-bottom: 0;
    }

    @include breakpoint-sm-below {
        grid-template-columns: 1fr;
    }

    @include breakpoint-sm-above {
        max-width: 462px;
        grid-template-columns: 1fr auto;
    }
}

.button {
    width: 100%;
}

.disclaimer {
    @include text-tiny;

    margin-top: $spacing-3;
    margin-bottom: 0;
}

.disclaimerLink {
    font-weight: $font-weight-medium;
    color: var(--prezly-accent-color);
    text-decoration: none;

    &:hover,
    &:focus {
        color: var(--prezly-accent-color-darkest);
    }
}
