    @import "styles/variables";
    @import "styles/mixins";

.container {
    position: relative;
}

@mixin absolute-menu-position {
    @include shadow-l;

    position: absolute;
    left: 0;
    transform-origin: top left;
    max-width: 280px;
    margin: $spacing-1 0 0;
    padding: $spacing-1;
    background: $color-base-white;
    border: 1px solid $color-borders;
    z-index: 10;

    @include breakpoint-sm-below {
        max-width: 280px;
    }
}

.menu {
    @include absolute-menu-position;

    list-style: none;
    overflow: hidden;
}

.container:last-child {
    .menu {
        left: auto;
        right: 0;
        transform-origin: top right;
    }
}

.caret {
    margin-left: $spacing-1;
    width: $spacing-2;
    height: $spacing-2;
}

.caretOpen {
    transform: rotate(180deg);
}

.transition {
    transition: opacity 0.2s ease, transform 0.2s ease;
}

.transitionOpenStart {
    opacity: 0;
    transform: scale(0.9);
}

.transitionOpenFinish {
    opacity: 1;
    transform: scale(1);
}
