    @import "styles/variables";
    @import "styles/mixins";

.container {
    display: flex;
    justify-content: space-between;
    border: none;
    margin-bottom: $spacing-5;
    padding: 0;
    flex-direction: column;

    @include breakpoint-md-above {
        flex-direction: row;
        padding: $spacing-3;
        border: 1px solid $color-base-200;
    }
}

.facets {
    padding: 0;
    display: flex;
    align-items: center;

    @include breakpoint-md-below {
        margin-top: $spacing-3;
        width: 100%;
        flex-direction: column;
        display: none;

        &.facetsOpen {
            display: flex;
        }
    }

    &:empty {
        display: none;
    }
}

.header {
    display: flex;
    align-items: flex-start;

    @include breakpoint-sm-above {
        min-width: 300px;
    }

    @include breakpoint-lg-above {
        min-width: 450px;
    }
}

.button {
    margin-left: $spacing-3;
    padding: calc(#{$spacing-3} - 1px) $spacing-3;

    @include breakpoint-md-above {
        display: none;
    }
}

.toggleFacets {
    justify-content: flex-start;
    padding-left: 0;
    margin-top: $spacing-3;
}
