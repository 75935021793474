    @import "styles/variables";
    @import "styles/mixins";

.container {
    margin-bottom: $spacing-6;
}

.title {
    @include heading-1;

    margin-top: 0;
    color: $color-base-700;

    &:only-child {
        margin-bottom: 0;
    }
}

.subtitle {
    @include paragraph;

    max-width: 735px;
    margin: 0;
}
