    @import "styles/variables";
    @import "styles/mixins";

.topic {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    text-decoration: none;
    padding: $spacing-4 $spacing-3;
    color: $color-base-white;
    position: relative;
    overflow: hidden;

    &::after {
        position: absolute;
        inset: 0;
        z-index: 1;
        content: "";
        background-image: linear-gradient(36deg, rgb(0 0 0 / 80%) 0%, rgb(0 0 0 / 0%) 70%);

        @include breakpoint-md-above {
            transition: transform 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
            transform: scale(1.1) translateY(0);
        }
    }

    @include breakpoint-md-above {
        &:hover::after {
            transform: scale(1.4) translateY(10%);
        }
    }

    .image {
        position: absolute;
        object-fit: cover;
        inset: 0;
        z-index: 0;
    }

    .content {
        position: relative;
        z-index: 2;
    }

    .title {
        @include reset-spacings;

        color: $color-base-white;
    }

    .subtitle {
        @include text-tiny;

        margin-top: $spacing-1;
    }

    &.usedInSearchOverlay {
        .title {
            @include paragraph;

            font-weight: $font-weight-medium;
        }
    }

    @include breakpoint-sm-below {
        &.usedInSearchOverlay {
            @include topic-aspect-ratio-horizontal;
        }

        &:not(.usedInSearchOverlay) {
            @include topic-aspect-ratio-mobile;
        }
    }

    @include breakpoint-sm-only {
        &.usedInSearchOverlay {
            @include topic-aspect-ratio-horizontal-secondary;

            padding: $spacing-2 $spacing-3;
        }

        &:not(.usedInSearchOverlay) {
            @include topic-aspect-ratio-horizontal;
        }
    }

    @include breakpoint-md-above {
        @include topic-aspect-ratio-vertical;
    }
}
