    @import "styles/variables";
    @import "styles/mixins";

.container {
    position: relative;
    display: grid;
    grid-template-columns: 335px 1fr;
    gap: $spacing-5;

    @include breakpoint-md-above {
        &:hover .image {
            transform: scale(1.05);
        }
    }

    @include breakpoint-md-below {
        grid-template-columns: 1fr;
        gap: $spacing-2;
    }
}

.imageWrapper {
    @include base-card-aspect-ratio;

    display: block;
    overflow: hidden;
    text-decoration: none;

    @include breakpoint-md-above {
        .image {
            transform: scale(1);
            transition: transform 0.25s ease-in-out;
        }
    }
}

.content {
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.title {
    @include heading-3;
    @include ensure-max-text-height(4, $line-height-l);
    @include search-result-title;

    font-weight: $font-weight-medium;
    margin-top: $spacing-1;
    margin-bottom: 0;

    &.noDateAndCategories {
        @include breakpoint-md-above {
            @include ensure-max-text-height(5, $line-height-s);
        }
    }
}

.titleLink {
    text-decoration: none;
    color: inherit;
    width: calc(100%);
    margin-top: 0;

    &:focus {
        color: $color-base-800;
    }
}
