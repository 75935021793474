    @import "styles/variables";
    @import "styles/mixins";

.imageContainer,
.image {
    height: 100%;
}

.imageContainer {
    display: block;
    position: relative;
}

.fallbackImageContainer {
    background: $color-base-900;
    display: flex;
    align-items: center;
    justify-content: center;
}

.fallbackImageWrapper {
    position: relative;
    height: 100%;
    max-width: 100%;
    aspect-ratio: 3 / 2;
}

.fallbackImage {
    object-fit: cover;
}
