    @import "styles/variables";
    @import "styles/mixins";

@import "variables";
@import "mixins";

.button {
    @include text-label;

    display: inline-flex;
    justify-content: center;
    align-items: center;
    padding: $spacing-2 $spacing-3;
    background: none;
    font-weight: $font-weight-medium;
    text-decoration: none;
    appearance: none;
    box-shadow: none;
    cursor: pointer;
    border: 1px solid transparent;

    &:disabled {
        cursor: not-allowed;
        opacity: 0.4;
    }

    &.loading {
        cursor: wait;
    }
}

.primary {
    background-color: var(--prezly-accent-color);
    border-color: var(--prezly-accent-color);
    color: var(--prezly-accent-color-button-text);

    &:not(:disabled) {
        &:hover,
        &:focus {
            background-color: var(--prezly-accent-color-darkest);
            border-color: var(--prezly-accent-color-darkest);
        }

        &:active {
            background-color: var(--prezly-accent-color-darkest);
            border-color: var(--prezly-accent-color-darkest);
        }
    }
}

.secondary {
    border-color: $color-borders;
    color: $color-base-700;

    &:not(:disabled) {
        &:hover {
            border-color: $color-base-400;
        }

        &:focus,
        &:active {
            border-color: var(--prezly-accent-color-lightest);
        }

        &:active {
            background-color: $color-base-50;
        }
    }
}

.navigation {
    color: $color-base-800;
    padding: $spacing-half $spacing-2;

    @media (hover: hover) {
        &:not(:disabled) {
            /* stylelint-disable-next-line max-nesting-depth */
            &:hover,
            &:focus {
                opacity: 0.8;
            }

            /* stylelint-disable-next-line max-nesting-depth */
            &:active {
                opacity: 1;
            }
        }
    }
}

.icon {
    width: 1em;
    height: 1em;

    &.loading {
        transform-origin: center;
        animation: spin 1s infinite linear;
    }

    &.left {
        margin-right: $spacing-1;
    }

    &.right {
        margin-left: $spacing-1;
    }
}

.iconOnly {
    .icon {
        margin: 0;
    }
}

@keyframes spin {
    from {
        transform: rotate(0);
    }

    to {
        transform: rotate(360deg);
    }
}
