    @import "styles/variables";
    @import "styles/mixins";

@import "variables";
@import "mixins";

.link {
    @include text-small;
    @include link-primary;

    font-weight: $font-weight-medium;
    text-decoration: none;
    cursor: pointer;

    &:not(:last-child) {
        &::after {
            content: ",";
            margin-right: $spacing-half;
        }
    }
}
