    @import "styles/variables";
    @import "styles/mixins";

.header {
    margin-bottom: $spacing-5;

    @include breakpoint-lg-above {
        margin-bottom: $spacing-8;
    }
}

.container {
    display: grid;
    gap: $spacing-5;

    @include breakpoint-md-above {
        grid-template-columns: 1fr 1fr;
    }

    @include breakpoint-lg-above {
        grid-template-columns: repeat(3, 1fr);
    }
}
