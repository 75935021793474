    @import "styles/variables";
    @import "styles/mixins";

.withStories {
    padding-top: 0;
}

.newsTopics {
    @include full-viewport-width;

    margin-top: $spacing-8;
    padding-top: $spacing-8;
    padding-bottom: $spacing-8;
    background-color: $color-base-900;
}

.noBottomPadding {
    padding-bottom: 0;
}

.highlightedStoryContainer {
    margin-bottom: $spacing-8;

    @include breakpoint-sm-only {
        margin-bottom: $spacing-5;
    }
}
