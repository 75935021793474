    @import "styles/variables";
    @import "styles/mixins";

.container {
    color: $color-base-700;
    text-decoration: none;

    &:hover,
    &:focus {
        color: $color-base-800;
    }
}

.thumbnailWrapper {
    position: relative;
}

.thumbnail {
    @include base-card-aspect-ratio;

    margin: 0 auto $spacing-2;
}

.iconWrapper {
    position: absolute;
    bottom: $spacing-2;
    right: $spacing-2;
    padding: $spacing-half * 1.5;
    background-color: rgba($color-base-white, 0.6);
    color: $color-base-900;
    line-height: 0;
}

.title {
    @include heading-3;
}
